#main{
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

p{
    font-family: var(--bold-font);
    font-size: 3rem;
    color: var(--tx);
    box-sizing: content-box;
}

h1{
    text-align: center;
    font-size: 32px;
}

.form {
    max-width: 30rem;
    margin: 0 auto;
    padding: 1.5rem 2rem;
    background-color: var(--bg2);
    border-radius: 8px;
    box-shadow: var(--card-shadow);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
}

input {
    border: 1px solid black;
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 0.75rem;
    padding: 10px;
    border-radius: 999px;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
}

input:focus{
    outline: none;
}

input:invalid:focus{
    box-shadow: 0 0 0 2px var(--error-color);
}

input:valid:focus{
    box-shadow: 0 0 0 2px var(--pop);
}

.has-dynamic-label {
    position: relative;
    padding-top: 1.5rem;
    display: block;
    width: 100%;
    margin: 0;
}

.has-dynamic-label label {
    position: absolute;
    bottom: 0;
    left: 10px;
    font-size: 1rem;
    opacity: 1;
    transform: translateY(-3rem);
    transition: all 0.2s ease-out;
}

input:valid {
    border-color: var(--pop);
    background-image: url("data:image/svg+xml,%3Csvg width='45px' height='34px' viewBox='0 0 45 34' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate%28-56.000000, -59.000000%29' fill='%232EEC96'%3E%3Cpolygon points='70.1468531 85.8671329 97.013986 59 100.58042 62.5664336 70.1468531 93 56 78.8531469 59.5664336 75.2867133'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

input:invalid {
    border-color: var(--error-color);
    background-image: url("data:image/svg+xml,%3Csvg width='30px' height='30px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate%28-128.000000, -59.000000%29' fill='%23F44336'%3E%3Cpolygon points='157.848404 61.9920213 145.980053 73.8603723 157.848404 85.7287234 154.856383 88.7207447 142.988032 76.8523936 131.119681 88.7207447 128.12766 85.7287234 139.996011 73.8603723 128.12766 61.9920213 131.119681 59 142.988032 70.8683511 154.856383 59'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

input:placeholder-shown + label {
    opacity: 0;
    transform: translateY(-1rem);
}

input:placeholder-shown{
    background-image: none;
    border-color: var(--pop);
}

input:placeholder-shown:focus{
    background-image: none;
    box-shadow: 0 0 0 2px var(--pop);
}

.submit{
    background-color: var(--pop);
    padding: 10px;
    border-radius: 999px;
    color: var(--txc);
    font-family: var(--bold-font);
    display: block;
    width: 100%;
    margin-top: 2.5rem;
    box-shadow: 0 0 4px var(--pop);
}

#toast-box{
    position: absolute;
    right: 16px;
    bottom: 16px;
    background-color: rgb(43, 43, 43);
    border-radius: 6px;
    padding: 4px 24px;
}

#toast-message{
    color: var(--error-color);
    font-size: 1.1rem;
    font-family: var(--text-font);
}