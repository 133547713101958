html{
    --bg1: rgb(231, 238, 253);
    --bg2: rgb(222, 230, 245);
    --bgc: #cdbfff;
    --tx: #081220;
    --txc: white;
    --pop: #852eb8;
    --black: #0A1824;
    --white: white;
    --title-font: 'Fugaz One', cursive;
    --subtitle-font: 'shrikhand', cursive;
    --text-font: 'text', sans-serif;
    --bold-font: 'bold-text', sans-serif;
    --card-shadow: 0px 0px 2px 1px rgb(221, 221, 221);
    --error-color: #f1448c;
}

html, body{
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: var(--bg1);
    overflow-x: hidden;
}

a{
    text-decoration: none;
}

button{
    border: 0;
    background-color: transparent;
    text-decoration: 0;
}