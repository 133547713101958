#bar{
    display: flex;
    align-items: left;
    justify-content: left;
    width: 100%;
    background-color: var(--bg1);
    z-index: 99999;
}

.main-title{
    font-family: var(--title-font);
    color: var(--pop);
    line-height: 1;
    margin: .5em 1em .4em .7em;
    font-size: 3em;
    text-decoration: none;
    text-shadow: 2px 2px var(--tx);
}

body{
    width: 100%;
    margin: 0;
}

.action{
    background-color: var(--pop);
    border-radius: 999px;
    margin: auto 32px auto auto;
    padding: 8px 16px;
    font-family: var(--bold-font);
    color: var(--bg1);
    box-shadow: 0 0 4px var(--pop);
    white-space: nowrap;
    transition: all ease .3s;
}

.action:hover{
    box-shadow: 0 0 6px 3px var(--pop);
}

.collapse{
    display: none;
}

#nav-list{
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.nav-item{
    margin: 0 32px;
    border-bottom: 2px solid transparent;
}

.nav-item > button{
    font-size: 1.6em;
    font-family: var(--bold-font);
    text-decoration: none;
    color: var(--tx)
}

.nav-item:hover{
    border-bottom: 2px solid var(--pop);
}

/* Collapse the menu */
@media only screen and (max-width: 1000px){
    #bar{
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        width: 100%;
        max-width: 100%;
        margin-bottom: 32px;
    }

    .nav-item > button{
        font-size: 1.6em;
    }

    .nav-item{
        margin: 0 20px;
    }

    .expand{
        display: none;
    }

    .collapse{
        display: inline;
    }
    
    #nav-list{
        grid-column-start: 1;
        grid-column-end: 3;
        margin: auto;
    }

    .main-title{
        font-size: 2.2em;
        margin: .5em auto .4em auto;
    }

    .action{
        margin: auto;
    }
}

/* Collapse the menu */
@media only screen and (max-width: 550px){
    #bar{
        flex-direction: column;
        align-items: center;
        margin-bottom: 32px;
    }

    .nav-item > button{
        font-size: 1.2em;
    }

    .nav-item{
        margin: 0 12px;
    }
}