#main{
    background-color: var(--bg1);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
}

section{
    display: flex;
    flex-direction: row;
    margin: 0px 12.5vw 64px 12.5vw;
    font-family: var(--bold-font);
    color: var(--tx);
    align-items: center;
    justify-content: space-evenly;
    opacity: 0;
}

.section-anim{
    animation-name: rush-up;
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
}

@keyframes rush-up {
    from {
        transform: translateY(30px);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        opacity: 1;
    }
  }

.card{
    padding: 16px;
    border-radius: 8px;
    box-shadow: var(--card-shadow);
    background-color: var(--bg1);
    margin: 16px;
}

.small-margin{
    margin: 4px;
    height: 80%;
}

.section-header{
    font-family: var(--bold-font);
    font-size: 3.2rem;
    margin: 16px 0px;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: var(--pop);
}

.normal-text{
    font-family: var(--text-font);
    color: var(--tx);
    font-size: 1.3rem;
    text-align: left;
}

.panel{
    display: flex;
    flex-direction: column;
}

#tagline{
    font-size: 5rem;
    text-shadow: 2px 2px var(--pop);
    margin-left: 0;
    margin-right: 0;
    text-align: center;
}

#example{
    
}

#example-site{
    width: 55vw;
    flex: 0;
    border-radius: 8px;
    box-shadow: 0 0 16px 4px var(--pop);
    z-index: 1;
    margin-left: -4rem;
}

#example-site:hover{
    box-shadow: 0 0 16px 8px var(--pop);
}

#example-explanation{
    text-align: center;
    z-index: 2;
    max-width: 30rem;
}

.fancy-header{
    font-family: var(--title-font);
}

#about{

}

.definition-word{
    font-size: 1.4rem;
    color: var(--pop);
    display: inline;
}

.definition-pron{
    display: inline;
    white-space: nowrap;
}

#features{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-auto-flow: dense;
}

#features-header{
    grid-column-start: 3;
    grid-column-end: 6;
}

#pricing{

}

#pricing-img{
    margin: 16px 32px;
    width: 25vw;
}

#footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: var(--bg2);
}

#disclaimer{
    font-family: var(--text-font);
    margin: 16px;
    font-size: 1em;
    color: var(--tx);
}

/* Stack the example/explanation */
@media only screen and (max-width: 1400px){
    #example{
        flex-direction: column-reverse;
    }

    #example-site{
        width: 100%;
    }
    
    #example-site{
        margin-left: unset;
        margin-bottom: -32px;
    }

    #features{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    
    #features-header{
        grid-column-start: 2;
        grid-column-end: 5;
    }
}

/* Stack the rest of the sections */
@media only screen and (max-width: 1250px){
    section{
        flex-direction: column;
        margin: 0px 8vw 64px 8vw;
    }

    #features{
        flex-direction: column-reverse;
    }

    #features{
        grid-template-columns: 1fr 1fr 1fr;
    }
    
    #features-header{
        grid-column-start: 1;
        grid-column-end: 4;
    }
}

/* Begin scaling everything with screen size */
@media only screen and (max-width: 950px){

    section{
        margin: 0px 6vw 32px 6vw;
    }

    #tagline{
        font-size: 8vw;
        text-shadow: 1px 1px var(--pop);
    }

    .fancy-header{
        font-size: min(5.5vw, 2.2em);
    }

    .normal-text{
        font-size: 3vw;
    }

    .definition-word{
        font-size: 4vw;
    }

    .section-header{
        font-size: 8vw;
    }

    #pricing-img{
        width: 57vw;
    }

    #features{
        grid-template-columns: 1fr 1fr;
    }
    
    #features-header{
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

@media only screen and (max-width: 550px){
    .card{
        margin: 8px;
    }

    #features{
        grid-template-columns: 1fr;
    }

    .small-margin{
        height: 65%;
    }
    
    #features-header{
        grid-column-start: 1;
        grid-column-end: 2;
    }
}